
        <template>
          <div class="vc-snippet-doc">
            <h2>NavMenu 导航菜单搜索</h2>
<p>单属性搜索、切换属性搜索、多属性模糊搜索以及多属性组合搜索。</p>
<h3>顶栏-“发起采购”按钮为固定的在前</h3>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"nav-menu__head"</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"nav-menu__child"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"medium"</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span>&gt;</span>发起采购<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">span</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"interval"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">span</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">ixu-nav-menu</span>
        <span class="hljs-attr">:list</span>=<span class="hljs-string">"list"</span>
        <span class="hljs-attr">:defaultActive</span>=<span class="hljs-string">"defaultActive"</span>
        @<span class="hljs-attr">click</span>=<span class="hljs-string">"click"</span>
        <span class="hljs-attr">:props</span>=<span class="hljs-string">"props"</span>
      &gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-nav-menu</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">span</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"span--left"</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"medium"</span> <span class="hljs-attr">icon</span>=<span class="hljs-string">"el-icon-menu"</span>&gt;</span>自定义显示<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"medium"</span> <span class="hljs-attr">icon</span>=<span class="hljs-string">"el-icon-search"</span>&gt;</span>高级筛选<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
      <span class="hljs-tag">&lt;/<span class="hljs-name">span</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
  data() {
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">list</span>: [
        {
          <span class="hljs-attr">id</span>: <span class="hljs-string">"0"</span>,
          <span class="hljs-attr">name</span>: <span class="hljs-string">"今日任务"</span>,
          <span class="hljs-attr">cornerMarkNum</span>:<span class="hljs-number">12</span>
        },
        {
          <span class="hljs-attr">id</span>: <span class="hljs-string">"1"</span>,
          <span class="hljs-attr">name</span>: <span class="hljs-string">"全部"</span>,
          <span class="hljs-attr">cornerMarkNum</span>:<span class="hljs-number">9999</span>
        },
        {
          <span class="hljs-attr">id</span>: <span class="hljs-string">"3"</span>,
          <span class="hljs-attr">name</span>: <span class="hljs-string">"周计划"</span>,
          <span class="hljs-attr">cornerMarkNum</span>:<span class="hljs-number">58</span>
        }
      ],
      <span class="hljs-attr">defaultActive</span>: <span class="hljs-number">0</span>, <span class="hljs-comment">//默认选中index</span>
      <span class="hljs-attr">props</span>: {
        <span class="hljs-attr">value</span>: <span class="hljs-string">"id:"</span>,
        <span class="hljs-attr">label</span>: <span class="hljs-string">"name"</span>,
        <span class="hljs-attr">cornerMarkNum</span>: <span class="hljs-string">"cornerMarkNum"</span>
      }
    };
  },
  <span class="hljs-attr">methods</span>: {
    click(item, index) {
      <span class="hljs-built_in">console</span>.log(<span class="hljs-string">"id"</span>, item.id, <span class="hljs-string">"index"</span>, index);
      <span class="hljs-built_in">console</span>.log(<span class="hljs-string">"name"</span>, item.name, <span class="hljs-string">"index"</span>, index);
    }
  }
};
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">style</span>&gt;</span>
.nav-menu__head {
  border: 1px solid #eeeff0;
  padding: 8px 20px 8px 20px;
  .nav-menu__child {
    .span--left {
      position: absolute;
      right: 0;
    }
    .interval {
      margin-right: 12px;
    }
  }
}
<span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>

</code></pre>
    </div>
                </vc-snippet> <h3>顶栏-复选</h3>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-1 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"nav-menu__head"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"nav-menu__child"</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"medium"</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span> <span class="hljs-attr">icon</span>=<span class="hljs-string">"el-icon-plus"</span>
          &gt;</span>新增表单
        <span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">span</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"interval"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">span</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">ixu-nav-menu</span>
          <span class="hljs-attr">:list</span>=<span class="hljs-string">"list"</span>
          <span class="hljs-attr">:defaultActive</span>=<span class="hljs-string">"defaultActive"</span>
          @<span class="hljs-attr">click</span>=<span class="hljs-string">"click"</span>
          <span class="hljs-attr">:props</span>=<span class="hljs-string">"props"</span>
        &gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-nav-menu</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">span</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"span--left"</span>&gt;</span>
          <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"medium"</span> <span class="hljs-attr">icon</span>=<span class="hljs-string">"el-icon-search"</span>&gt;</span>高级筛选<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">span</span>&gt;</span>
      <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">v-show</span>=<span class="hljs-string">"show"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"nav-menu__main"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">el-radio-group</span>
        <span class="hljs-attr">v-show</span>=<span class="hljs-string">"show"</span>
        <span class="hljs-attr">v-model</span>=<span class="hljs-string">"radioAllDefault"</span>
        @<span class="hljs-attr">change</span>=<span class="hljs-string">"checked()"</span>
      &gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-radio</span> <span class="hljs-attr">:label</span>=<span class="hljs-string">"0"</span>&gt;</span>全部<span class="hljs-tag">&lt;/<span class="hljs-name">el-radio</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-radio</span> <span class="hljs-attr">:label</span>=<span class="hljs-string">"1"</span>&gt;</span>待发货<span class="hljs-tag">&lt;/<span class="hljs-name">el-radio</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-radio</span> <span class="hljs-attr">:label</span>=<span class="hljs-string">"2"</span>&gt;</span>待收货<span class="hljs-tag">&lt;/<span class="hljs-name">el-radio</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-radio</span> <span class="hljs-attr">:label</span>=<span class="hljs-string">"3"</span>&gt;</span>待验收<span class="hljs-tag">&lt;/<span class="hljs-name">el-radio</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-radio</span> <span class="hljs-attr">:label</span>=<span class="hljs-string">"4"</span>&gt;</span>待入库<span class="hljs-tag">&lt;/<span class="hljs-name">el-radio</span>&gt;</span>
      <span class="hljs-tag">&lt;/<span class="hljs-name">el-radio-group</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
  data() {
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">show</span>: <span class="hljs-literal">false</span>,
      <span class="hljs-attr">list</span>: [
        {
          <span class="hljs-attr">value</span>: <span class="hljs-string">"0"</span>,
          <span class="hljs-attr">label</span>: <span class="hljs-string">"质检反馈表单"</span>
        },
        {
          <span class="hljs-attr">value</span>: <span class="hljs-string">"1"</span>,
          <span class="hljs-attr">label</span>: <span class="hljs-string">"质检评价表单"</span>
        }
      ],
      <span class="hljs-attr">defaultActive</span>: <span class="hljs-number">0</span>, <span class="hljs-comment">//默认选中</span>
      <span class="hljs-attr">radioAllDefault</span>: <span class="hljs-number">0</span>,
      <span class="hljs-attr">props</span>: {
        <span class="hljs-attr">value</span>: <span class="hljs-string">"value"</span>,
        <span class="hljs-attr">label</span>: <span class="hljs-string">"label"</span>
      }
    };
  },
  created() {
    <span class="hljs-keyword">this</span>.initialize();
  },
  <span class="hljs-attr">methods</span>: {
    initialize() {
      <span class="hljs-keyword">if</span> (<span class="hljs-keyword">this</span>.radioAllDefault === <span class="hljs-number">0</span>) {
        <span class="hljs-keyword">this</span>.show = <span class="hljs-literal">true</span>;
      }
    },
    click(item, index) {
      <span class="hljs-built_in">console</span>.log(<span class="hljs-string">"value"</span>, item.value, <span class="hljs-string">"index"</span>, index);
      <span class="hljs-built_in">console</span>.log(<span class="hljs-string">"label"</span>, item.label, <span class="hljs-string">"index"</span>, index);
      <span class="hljs-keyword">if</span> (item.value === <span class="hljs-string">"0"</span>) {
        <span class="hljs-keyword">this</span>.show = <span class="hljs-literal">true</span>;
      } <span class="hljs-keyword">else</span> {
        <span class="hljs-keyword">this</span>.show = <span class="hljs-literal">false</span>;
      }
    },
    checked() {
      <span class="hljs-built_in">console</span>.log(<span class="hljs-keyword">this</span>.radioAllDefault);
    }
  }
};
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">style</span>&gt;</span>
.nav-menu__head {
  border: 1px solid #eeeff0;
  padding: 8px 20px 8px 20px;
  .nav-menu__child {
    position: relative;
    .span--left {
      position: absolute;
      right: 0;
    }
    .interval {
      margin-right: 12px;
    }
  }
}
.nav-menu__main {
  border: 1px solid #eeeff0;
  border-top-width: 0;
  padding: 0 20px 20px 20px;
  .el-radio-group {
    margin-top: 20px;
  }
}
<span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>

</code></pre>
    </div>
                </vc-snippet> <h3>顶栏-如“企业采购-采购计划”</h3>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-2 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"nav-menu__head"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"nav-menu__child"</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">ixu-nav-menu</span>
          <span class="hljs-attr">:list</span>=<span class="hljs-string">"list"</span>
          <span class="hljs-attr">:defaultActive</span>=<span class="hljs-string">"defaultActive"</span>
          @<span class="hljs-attr">click</span>=<span class="hljs-string">"click"</span>
          <span class="hljs-attr">:props</span>=<span class="hljs-string">"props"</span>
        &gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-nav-menu</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"medium"</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span> <span class="hljs-attr">plain</span>&gt;</span>提交采购计划<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"div--left"</span>&gt;</span>
          <span class="hljs-tag">&lt;<span class="hljs-name">el-input</span>
            <span class="hljs-attr">style</span>=<span class="hljs-string">"margin-right: 10px"</span>
            <span class="hljs-attr">size</span>=<span class="hljs-string">"medium"</span>
            <span class="hljs-attr">placeholder</span>=<span class="hljs-string">"产品名称/编号"</span>
            <span class="hljs-attr">prefix-icon</span>=<span class="hljs-string">"el-icon-search"</span>
            <span class="hljs-attr">clearable</span>
          &gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">el-input</span>&gt;</span>
          <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"medium"</span> <span class="hljs-attr">icon</span>=<span class="hljs-string">"el-icon-search"</span>&gt;</span>高级筛选<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
          <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"medium"</span> <span class="hljs-attr">icon</span>=<span class="hljs-string">"el-icon-menu"</span>&gt;</span>自定义显示<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
      <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">v-show</span>=<span class="hljs-string">"show"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"nav-menu__main"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">el-radio-group</span>
        <span class="hljs-attr">v-show</span>=<span class="hljs-string">"show"</span>
        <span class="hljs-attr">v-model</span>=<span class="hljs-string">"radioAllDefault"</span>
        @<span class="hljs-attr">change</span>=<span class="hljs-string">"checked()"</span>
      &gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-radio</span> <span class="hljs-attr">:label</span>=<span class="hljs-string">"0"</span>&gt;</span>全部<span class="hljs-tag">&lt;/<span class="hljs-name">el-radio</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-radio</span> <span class="hljs-attr">:label</span>=<span class="hljs-string">"1"</span>&gt;</span>待发货<span class="hljs-tag">&lt;/<span class="hljs-name">el-radio</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-radio</span> <span class="hljs-attr">:label</span>=<span class="hljs-string">"2"</span>&gt;</span>待收货<span class="hljs-tag">&lt;/<span class="hljs-name">el-radio</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-radio</span> <span class="hljs-attr">:label</span>=<span class="hljs-string">"3"</span>&gt;</span>待验收<span class="hljs-tag">&lt;/<span class="hljs-name">el-radio</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">el-radio</span> <span class="hljs-attr">:label</span>=<span class="hljs-string">"4"</span>&gt;</span>待入库<span class="hljs-tag">&lt;/<span class="hljs-name">el-radio</span>&gt;</span>
      <span class="hljs-tag">&lt;/<span class="hljs-name">el-radio-group</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
  data() {
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">show</span>: <span class="hljs-literal">false</span>,
      <span class="hljs-attr">list</span>: [
        {
          <span class="hljs-attr">value</span>: <span class="hljs-string">"0"</span>,
          <span class="hljs-attr">label</span>: <span class="hljs-string">"待采购"</span>,
        },
        {
          <span class="hljs-attr">value</span>: <span class="hljs-string">"1"</span>,
          <span class="hljs-attr">label</span>: <span class="hljs-string">"报价中"</span>,
        }
      ],
      <span class="hljs-attr">defaultActive</span>: <span class="hljs-number">0</span>, <span class="hljs-comment">//默认选中</span>
      <span class="hljs-attr">radioAllDefault</span>: <span class="hljs-number">1</span>,
      <span class="hljs-attr">props</span>: {
        <span class="hljs-attr">value</span>: <span class="hljs-string">"value"</span>,
        <span class="hljs-attr">label</span>: <span class="hljs-string">"label"</span>
      }
    };
  },
  created() {
    <span class="hljs-keyword">this</span>.initialize();
  },
  <span class="hljs-attr">methods</span>: {
    initialize() {
      <span class="hljs-keyword">if</span> (<span class="hljs-keyword">this</span>.radioAllDefault === <span class="hljs-number">0</span>) {
        <span class="hljs-keyword">this</span>.show = <span class="hljs-literal">true</span>;
      }
    },
    click(item, index) {
      <span class="hljs-built_in">console</span>.log(<span class="hljs-string">"value"</span>, item.value, <span class="hljs-string">"index"</span>, index);
      <span class="hljs-built_in">console</span>.log(<span class="hljs-string">"label"</span>, item.label, <span class="hljs-string">"index"</span>, index);
      <span class="hljs-keyword">if</span> (item.value === <span class="hljs-string">"1"</span>) {
        <span class="hljs-keyword">this</span>.show = <span class="hljs-literal">true</span>;
      } <span class="hljs-keyword">else</span> {
        <span class="hljs-keyword">this</span>.show = <span class="hljs-literal">false</span>;
      }
    },
    checked() {
      <span class="hljs-built_in">console</span>.log(<span class="hljs-keyword">this</span>.radioAllDefault);
    }
  }
};
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">style</span>&gt;</span>
.nav-menu__head {
  border: 1px solid #eeeff0;
  padding: 8px 20px 8px 20px;
  .nav-menu__child {
    display: flex;
    position: relative;
    .div--left {
      display: flex;
      position: absolute;
      right: 0;
    }
    .interval {
      margin-right: 12px;
    }
  }
}
.nav-menu__main {
  border: 1px solid #eeeff0;
  border-top-width: 0;
  padding: 0 20px 20px 20px;
  .el-radio-group {
    margin-top: 20px;
  }
}
<span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>

</code></pre>
    </div>
                </vc-snippet> <h3>顶栏-如“商城客户-客户管理”搜索优化留一个在外面其他放到高级筛选里面</h3>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-3 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"nav-menu__head"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"nav-menu__child"</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">ixu-nav-menu</span>
          <span class="hljs-attr">:list</span>=<span class="hljs-string">"list"</span>
          <span class="hljs-attr">:defaultActive</span>=<span class="hljs-string">"defaultActive"</span>
          @<span class="hljs-attr">click</span>=<span class="hljs-string">"click"</span>
          <span class="hljs-attr">:props</span>=<span class="hljs-string">"props"</span>
        &gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-nav-menu</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"div--left"</span>&gt;</span>
          <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"medium"</span> <span class="hljs-attr">icon</span>=<span class="hljs-string">"el-icon-search"</span>&gt;</span>高级筛选<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
      <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
  data() {
    <span class="hljs-keyword">return</span> {
      <span class="hljs-attr">list</span>: [
        {
          <span class="hljs-attr">value</span>: <span class="hljs-string">"0"</span>,
          <span class="hljs-attr">label</span>: <span class="hljs-string">"客户管理"</span>
        }
      ],
      <span class="hljs-attr">defaultActive</span>: <span class="hljs-number">0</span>, <span class="hljs-comment">//默认选中</span>
      <span class="hljs-attr">props</span>: {
        <span class="hljs-attr">value</span>: <span class="hljs-string">"value"</span>,
        <span class="hljs-attr">label</span>: <span class="hljs-string">"label"</span>
      }
    };
  },
  created() {},
  <span class="hljs-attr">methods</span>: {
    click(item, index) {
      <span class="hljs-built_in">console</span>.log(<span class="hljs-string">"value"</span>, item.value, <span class="hljs-string">"index"</span>, index);
      <span class="hljs-built_in">console</span>.log(<span class="hljs-string">"label"</span>, item.label, <span class="hljs-string">"index"</span>, index);
    }
  }
};
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">style</span>&gt;</span>
.nav-menu__head {
  border: 1px solid #eeeff0;
  padding: 8px 20px 8px 20px;
  .nav-menu__child {
    display: flex;
    position: relative;
    .div--left {
      display: flex;
      position: absolute;
      right: 0;
    }
  }
}
<span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>

</code></pre>
    </div>
                </vc-snippet> <h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>list</td>
<td>导航菜单集合</td>
<td>Array</td>
<td>—</td>
<td></td>
</tr>
<tr>
<td>props</td>
<td>配置选项，具体见下表</td>
<td>Object</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>defaultActive</td>
<td>默认选中的菜单index</td>
<td>Number</td>
<td>—</td>
<td>0</td>
</tr>
</tbody>
</table>
<h3>Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>click</td>
<td>点击 菜单 时触发的事件</td>
<td>选中的 菜单对象value和label以及选中的index</td>
</tr>
</tbody>
</table>
<h3>Props</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>value</td>
<td>指定选项标签为选项对象的某个属性值对应‘list’集合中单个对象的属性例如value</td>
<td>string</td>
<td>—</td>
<td>'value'</td>
</tr>
<tr>
<td>label</td>
<td>指定选项标签为选项对象的某个属性值对应‘list’集合中单个对象的属性例如label</td>
<td>string</td>
<td>—</td>
<td>'label'</td>
</tr>
<tr>
<td>cornerMarkNum</td>
<td>角标属性指定选项标签为选项对象的某个属性值对应‘list’集合中单个对象的属性例如cornerMarkNum</td>
<td>string</td>
<td>—</td>
<td>'cornerMarkNum'</td>
</tr>
</tbody>
</table>

          </div>
        </template>
        <script>
           //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-menu__head"},[_c('div',{staticClass:"nav-menu__child"},[_c('el-button',{attrs:{"size":"medium","type":"primary"}},[_vm._v("发起采购")]),_vm._v(" "),_c('span',{staticClass:"interval"}),_vm._v(" "),_c('ixu-nav-menu',{attrs:{"list":_vm.list,"defaultActive":_vm.defaultActive,"props":_vm.props},on:{"click":_vm.click}}),_vm._v(" "),_c('span',{staticClass:"span--left"},[_c('el-button',{attrs:{"size":"medium","icon":"el-icon-menu"}},[_vm._v("自定义显示")]),_vm._v(" "),_c('el-button',{attrs:{"size":"medium","icon":"el-icon-search"}},[_vm._v("高级筛选")])],1)],1)])}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      list: [
        {
          id: "0",
          name: "今日任务",
          cornerMarkNum:12
        },
        {
          id: "1",
          name: "全部",
          cornerMarkNum:9999
        },
        {
          id: "3",
          name: "周计划",
          cornerMarkNum:58
        }
      ],
      defaultActive: 0, //默认选中index
      props: {
        value: "id:",
        label: "name",
        cornerMarkNum: "cornerMarkNum"
      }
    };
  },
  methods: {
    click(item, index) {
      console.log("id", item.id, "index", index);
      console.log("name", item.name, "index", index);
    }
  }
};

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-1":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"nav-menu__head"},[_c('div',{staticClass:"nav-menu__child"},[_c('el-button',{attrs:{"size":"medium","type":"primary","icon":"el-icon-plus"}},[_vm._v("新增表单\n      ")]),_vm._v(" "),_c('span',{staticClass:"interval"}),_vm._v(" "),_c('ixu-nav-menu',{attrs:{"list":_vm.list,"defaultActive":_vm.defaultActive,"props":_vm.props},on:{"click":_vm.click}}),_vm._v(" "),_c('span',{staticClass:"span--left"},[_c('el-button',{attrs:{"size":"medium","icon":"el-icon-search"}},[_vm._v("高级筛选")])],1)],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"nav-menu__main"},[_c('el-radio-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],on:{"change":function($event){return _vm.checked()}},model:{value:(_vm.radioAllDefault),callback:function ($$v) {_vm.radioAllDefault=$$v},expression:"radioAllDefault"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("全部")]),_vm._v(" "),_c('el-radio',{attrs:{"label":1}},[_vm._v("待发货")]),_vm._v(" "),_c('el-radio',{attrs:{"label":2}},[_vm._v("待收货")]),_vm._v(" "),_c('el-radio',{attrs:{"label":3}},[_vm._v("待验收")]),_vm._v(" "),_c('el-radio',{attrs:{"label":4}},[_vm._v("待入库")])],1)],1)])}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      show: false,
      list: [
        {
          value: "0",
          label: "质检反馈表单"
        },
        {
          value: "1",
          label: "质检评价表单"
        }
      ],
      defaultActive: 0, //默认选中
      radioAllDefault: 0,
      props: {
        value: "value",
        label: "label"
      }
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.radioAllDefault === 0) {
        this.show = true;
      }
    },
    click(item, index) {
      console.log("value", item.value, "index", index);
      console.log("label", item.label, "index", index);
      if (item.value === "0") {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    checked() {
      console.log(this.radioAllDefault);
    }
  }
};

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-2":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"nav-menu__head"},[_c('div',{staticClass:"nav-menu__child"},[_c('ixu-nav-menu',{attrs:{"list":_vm.list,"defaultActive":_vm.defaultActive,"props":_vm.props},on:{"click":_vm.click}}),_vm._v(" "),_c('el-button',{attrs:{"size":"medium","type":"primary","plain":""}},[_vm._v("提交采购计划")]),_vm._v(" "),_c('div',{staticClass:"div--left"},[_c('el-input',{staticStyle:{"margin-right":"10px"},attrs:{"size":"medium","placeholder":"产品名称/编号","prefix-icon":"el-icon-search","clearable":""}}),_vm._v(" "),_c('el-button',{attrs:{"size":"medium","icon":"el-icon-search"}},[_vm._v("高级筛选")]),_vm._v(" "),_c('el-button',{attrs:{"size":"medium","icon":"el-icon-menu"}},[_vm._v("自定义显示")])],1)],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"nav-menu__main"},[_c('el-radio-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],on:{"change":function($event){return _vm.checked()}},model:{value:(_vm.radioAllDefault),callback:function ($$v) {_vm.radioAllDefault=$$v},expression:"radioAllDefault"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("全部")]),_vm._v(" "),_c('el-radio',{attrs:{"label":1}},[_vm._v("待发货")]),_vm._v(" "),_c('el-radio',{attrs:{"label":2}},[_vm._v("待收货")]),_vm._v(" "),_c('el-radio',{attrs:{"label":3}},[_vm._v("待验收")]),_vm._v(" "),_c('el-radio',{attrs:{"label":4}},[_vm._v("待入库")])],1)],1)])}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      show: false,
      list: [
        {
          value: "0",
          label: "待采购",
        },
        {
          value: "1",
          label: "报价中",
        }
      ],
      defaultActive: 0, //默认选中
      radioAllDefault: 1,
      props: {
        value: "value",
        label: "label"
      }
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.radioAllDefault === 0) {
        this.show = true;
      }
    },
    click(item, index) {
      console.log("value", item.value, "index", index);
      console.log("label", item.label, "index", index);
      if (item.value === "1") {
        this.show = true;
      } else {
        this.show = false;
      }
    },
    checked() {
      console.log(this.radioAllDefault);
    }
  }
};

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-3":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"nav-menu__head"},[_c('div',{staticClass:"nav-menu__child"},[_c('ixu-nav-menu',{attrs:{"list":_vm.list,"defaultActive":_vm.defaultActive,"props":_vm.props},on:{"click":_vm.click}}),_vm._v(" "),_c('div',{staticClass:"div--left"},[_c('el-button',{attrs:{"size":"medium","icon":"el-icon-search"}},[_vm._v("高级筛选")])],1)],1)])])}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      list: [
        {
          value: "0",
          label: "客户管理"
        }
      ],
      defaultActive: 0, //默认选中
      props: {
        value: "value",
        label: "label"
      }
    };
  },
  created() {},
  methods: {
    click(item, index) {
      console.log("value", item.value, "index", index);
      console.log("label", item.label, "index", index);
    }
  }
};

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
























































.nav-menu__head {
  border: 1px solid #eeeff0;
  padding: 8px 20px 8px 20px;
  .nav-menu__child {
    .span--left {
      position: absolute;
      right: 0;
    }
    .interval {
      margin-right: 12px;
    }
  }
}



















































































.nav-menu__head {
  border: 1px solid #eeeff0;
  padding: 8px 20px 8px 20px;
  .nav-menu__child {
    position: relative;
    .span--left {
      position: absolute;
      right: 0;
    }
    .interval {
      margin-right: 12px;
    }
  }
}
.nav-menu__main {
  border: 1px solid #eeeff0;
  border-top-width: 0;
  padding: 0 20px 20px 20px;
  .el-radio-group {
    margin-top: 20px;
  }
}
























































































.nav-menu__head {
  border: 1px solid #eeeff0;
  padding: 8px 20px 8px 20px;
  .nav-menu__child {
    display: flex;
    position: relative;
    .div--left {
      display: flex;
      position: absolute;
      right: 0;
    }
    .interval {
      margin-right: 12px;
    }
  }
}
.nav-menu__main {
  border: 1px solid #eeeff0;
  border-top-width: 0;
  padding: 0 20px 20px 20px;
  .el-radio-group {
    margin-top: 20px;
  }
}













































.nav-menu__head {
  border: 1px solid #eeeff0;
  padding: 8px 20px 8px 20px;
  .nav-menu__child {
    display: flex;
    position: relative;
    .div--left {
      display: flex;
      position: absolute;
      right: 0;
    }
  }
}

       </style>